
.generate-product(@mime, @content, @color){
    background-image: none;
    font-family: 'trisotech-product';
    font-style: normal;
    font-size: 20px;
    margin-top: 0px;
    speak: none;
    &:before {
        content: @content;
        color: @color;
    }
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='bpmn'] i[class^='img-'] {
        .generate-product("bpmn", "\e600", #0866AC);
    }
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='cmmn'] i[class^='img-'] {
        .generate-product("cmmn", "\e601", #16884A);
    }
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='discovery'] i[class^='img-'] {
        .generate-product("discovery", "\e602", #ED5724);
    }
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='dmn'] i[class^='img-'] {
        .generate-product("dmn", "\e603",#EFA91B);
    }
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='sdmn'] i[class^='img-'] {
        .generate-product("sdmn", "\e91d", #147598);
    }
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='landscaping'] i[class^='img-'] {
        .generate-product("landscaping", "\e902",#e72c2e);
    }
}

div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='capability'] i[class^='img-'] {
        .generate-product("capability", "\e904", #7B67AE);
    }
}

div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='businessentity'] i[class^='img-'] {
        .generate-product("businessentity", "\e908", #e5185c);
    }
}

div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul, .side-panel, .dropdown-content ul {
    .item[data-mimetype*='pmml'] i[class^='img-'] {
        .generate-product("pmml", "\e90c", #848484);
    }
}
